body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-viga {
  font-family: "Viga", sans-serif;
  width: 100%;
}

.font-voces {
  font-family: "Voces", cursive;
  width: 100%;
}

.text-justify {
  text-align: justify;
}

.text-decoration-none {
  color: black;
  text-decoration: none;
}

.text-decoration-none:hover {
  color: black;
  text-decoration: none;
}

.out-of-navbar {
  margin-top: 6rem;
}

.h-full {
  height: 100vh;
}

.h-screen {
  min-height: 100vh;
}

.border-none {
  border: none;
  outline: none;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(225, 225, 225, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;

  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c5c5c;
}
@media (min-width: 768px) {
  .container {
    max-width: 1140px !important;
  }
}
