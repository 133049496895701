.kastrat-nav-container,
.kastrat-footer-container,
.kastrat-detail-main-container,
.add-kastrat-container {
  margin: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  font-family: "Nunito", sans-serif;
}

.kastrat-nav-container {
  border-bottom: 1px solid #b8b6b6;
}

.kastrat-nav-container .brand h1 {
  font-weight: 800;
}

.kastrat-nav-container .kastrat-nav-link {
  font-size: 20px;
}

.kastrat-nav-container .search-input:focus {
  border: 1px solid #559f4b;
  box-shadow: 0 0 0 0.3rem rgba(85, 159, 75, 0.25);
}

.loged-in .image-logedin-container {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  box-shadow: -2px 2px 5px 1px rgb(148, 148, 148);
}
.loged-in img {
  width: 40px;
  height: 40px;
}

.loged-in .name-logedin-container {
  font-size: 12px;
  font-weight: 800;
  color: #000;
  cursor: pointer;
}
.kastrat-main-container {
  border-bottom: 1px solid #eaeaea;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
}

.dropdown-toggle:focus {
  border: 0;
}
.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px 1px rgb(161, 161, 161);
}

.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #4a4a4a;
  display: block;
  width: 0;
}

.drop-logout::after {
  display: none;
  width: 0;
}

.dropdown-item {
  color: #000;
}

.dropdown-item:hover {
  background-color: #e65561;
  color: #fff !important;
}

.btn-add-kastrat {
  background-color: #559f4b;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  padding: 0.5rem 1rem;
  font-weight: 700;
}

.btn-add-kastrat:hover {
  background-color: #408637;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  padding: 0.5rem 1rem;
  font-weight: 700;
}

.year {
  border-bottom: 1px solid #eaeaea;
}

.kastrat-main-container .year h5 {
  font-size: 16px;
  font-weight: 800;
  color: #5c5c5c;
}

.kastrat-main-container .kastrat-main-content a {
  text-decoration: none;
  color: #000;
}

.kastrat-main-container .kastrat-main-content .title h4,
.kastrat-main-container .kastrat-main-content .title-more h4 {
  font-weight: 700;
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Jumlah baris yang ingin Anda tampilkan */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kastrat-main-container .kastrat-main-content .header img {
  width: 100%;
  height: 15rem;
  object-fit: cover;
}

.kastrat-main-container .kastrat-main-content .subject h5 {
  font-size: 15px;
  color: #828282;
  white-space: nowrap; /* Agar teks tidak pindah ke baris berikutnya */
  overflow: hidden; /* Agar teks yang melebihi lebar tersembunyi */
  text-overflow: ellipsis;
}
.kastrat-main-container .kastrat-main-content .subject-more h5 {
  font-size: 14px;
  color: #828282;
  white-space: nowrap; /* Agar teks tidak pindah ke baris berikutnya */
  overflow: hidden; /* Agar teks yang melebihi lebar tersembunyi */
  text-overflow: ellipsis;
}

.kastrat-main-container .kastrat-main-content .author .author-img {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  box-shadow: -2px 5px 10px 5px #eaeaea;
}

.kastrat-main-container .kastrat-main-content .author .author-name h6 {
  font-weight: 700;
  font-size: 14px;
  color: #559f4b;
}

.kastrat-main-container .kastrat-main-content .author .author-name p {
  font-size: 12px;
  color: #828282;
}

.kastrat-main-container .kastrat-main-content .header-more img {
  width: 100%;
  height: 13rem;
  object-fit: cover;
}

.kastrat-footer-container {
  font-family: "Quicksand", sans-serif !important;
}

.kastrat-footer-container .copyright-left img {
  width: 15rem;
}

.kastrat-footer-container .copyright-center a {
  text-decoration: underline;
  color: #000;
  font-size: 14px;
}

.kastrat-footer-container .copyright-right a {
  text-decoration: none;
  color: #000;
  font-size: 25px;
  display: inline-block;
  margin: 1.5rem 1.5rem;
}

/* Detail */
.position-fixed {
  top: auto;
  position: relative !important;
}

.kastrat-nav-detail-container {
  border-bottom: 1px solid #b8b6b6;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
}

.kastrat-nav-detail-container .search-detail-input {
  border: 1px solid #d2d2d2;
  background-color: #ededed;
  border-radius: 10px;
  font-size: 14px;
  padding: 0.2rem 1rem;
}

.kastrat-nav-detail-container .search-detail-input input {
  border: none;
  background-color: #ededed;
  border-radius: 0;
  font-size: 14px;
  outline: none;
}

.kastrat-nav-detail-container .kastrat-nav-link {
  font-size: 20px;
}

.img-detail {
  width: 5rem;
}

.kastrat-detail-main-container {
  font-family: "Quicksand", sans-serif !important;
  border: none;
  overflow-x: hidden;
}

.kastrat-detail-main-left {
  overflow-x: hidden;
}

.kastrat-detail-main-left .header {
  box-shadow: -4px 15px 10px 1px #eaeaea;
  border-radius: 10px;
}

.kastrat-detail-main-left .content .uploader img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: -2px 5px 10px 5px #eaeaea;
}

.kastrat-detail-main-left .content .uploader .author-detail .author-name p {
  font-size: 14px;
  color: #929292;
}
.kastrat-detail-main-left .content .uploader .author-detail .author-name h6 {
  font-weight: 700;
}

.kastrat-detail-main-left .content .uploader i {
  font-size: 14px;
  color: #929292;
}

.kastrat-detail-main-left .content .uploader i:hover {
  cursor: pointer;
  color: #000;
}

.kastrat-detail-main-left .content .title h3 {
  font-weight: 1000;
}

.kastrat-detail-main-left .content .subject h5 {
  font-weight: 700;
}

.kastrat-detail-main-left .ql-editor img {
  width: 100%;
}

.kastrat-detail-main-right {
  border-left: none;
  border-bottom: 1px solid #828282;
  height: 100vh;
}

.kastrat-detail-main-right .header .author-img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  box-shadow: -2px 5px 10px 5px #eaeaea;
}

.kastrat-detail-main-right .header .author-name h5 {
  font-weight: 700;
}

.kastrat-detail-main-right .header .author-name p {
  font-size: 14px;
  color: #787878;
}

.kastrat-detail-main-right .header .author-interact .btn {
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
}

.kastrat-detail-main-right .header .author-interact .btn-success {
  background-color: #559f4b;
  border: none;
}

.kastrat-detail-main-right .header .author-interact .btn-success:hover {
  background-color: #396932;
  border: none;
}
.kastrat-detail-main-right .content a {
  text-decoration: none;
  color: #000;
}

.kastrat-detail-main-right .content .content-title h6 {
  font-weight: 700;
}

.kastrat-detail-main-right .content .content-main h6 {
  font-weight: 700;
  font-size: 12px;
}

.kastrat-detail-main-right .content .content-main .author-img img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: -2px 5px 10px 5px #eaeaea;
}

.kastrat-detail-main-right .content .content-main .author-img p {
  font-size: 10px;
}

.kastrat-detail-main-right .content .content-main .content-main-right img {
  width: 15rem;
}

.kastrat-detail-main-right .footer {
  position: absolute;
  bottom: 0;
}

.kastrat-detail-main-right .footer a {
  color: #626262;
  font-size: 12px;
}

.add-kastrat-container .btn-submit {
  margin-top: 1rem;
  padding-bottom: 5rem;
  border-bottom: 1px solid #828282;
}

.form-floating input:focus {
  border: 1px solid #559f4b;
  box-shadow: 0 0 0 0.3rem rgba(85, 159, 75, 0.25);
}

.kastrat-detail-main-left input:focus {
  border: 1px solid #559f4b;
  box-shadow: 0 0 0 0.3rem rgba(85, 159, 75, 0.25);
}

.add-kastrat-container .quill {
  padding-bottom: 6rem;
}

.quill img {
  width: 100%;
}

@media (min-width: 768px) {
  .add-kastrat-container {
    margin: 0 180px !important;
    padding: 0 0 !important;
    font-family: "Nunito", sans-serif;
  }

  .kastrat-main-container .kastrat-main-content .title h4 {
    font-weight: 700;
    font-size: 20px;
  }

  .kastrat-main-container .kastrat-main-content .author .author-img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-shadow: -2px 5px 10px 5px #eaeaea;
  }

  .kastrat-main-container .kastrat-main-content .header img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }

  .kastrat-detail-main-container {
    border-bottom: 1px solid #828282;
    overflow-x: visible;
  }

  .kastrat-detail-main-right {
    border-left: 1px solid #b8b6b6;
    border-bottom: none;
    height: 100vh;
  }

  .kastrat-footer-container .copyright-right a {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
  }

  .position-fixed {
    top: 0;
    position: sticky !important;
  }

  .add-kastrat-container .quill {
    padding-bottom: 2.6rem;
  }
}
