.about-us {
  background-image: url("../../../../assets/smf.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
}

.about-us p {
  font-size: 12px;
  width: 70%;
}

@media (min-width: 768px) {
  .about-us p {
    font-size: 14px;
    width: 60%;
  }
}
