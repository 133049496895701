.nav-profile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.863);
  backdrop-filter: blur(2px);
  z-index: -1;
}

.nav-profile {
  background: transparent; /* Atur latar belakang navbar menjadi transparan */
  position: fixed;
  width: 100%;
  z-index: 1;
}
