.kegiatan-nav {
  box-shadow: 0 5px 10px 1px #eaeaea;
  font-family: "Nunito", sans-serif;
}

.kegiatan-nav .image-nav img {
  width: 70px;
  object-fit: cover;
}

.kegiatan-nav .image-nav h4 {
  font-weight: 700;
}

.kegiatan-content {
  font-family: "Quicksand", sans-serif;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 2rem;
}

.kegiatan-content a {
  color: black;
  text-decoration: none;
}

.kegiatan-content .search-container {
  display: flex;
  justify-content: center;
}

.kegiatan-content .search-input {
  width: 100%;
  margin: 2rem;
  margin-top: 0;
  border: 1px solid rgb(141, 141, 141);
  border-radius: 5px;
}

.kegiatan-content .search-input.focus {
  border: 1px solid #559f4b;
  box-shadow: 0 0 0 0.3rem rgba(85, 159, 75, 0.25);
}

.kegiatan-content .search-input input {
  border: none;
}
.kegiatan-content .search-input input:focus {
  border: none;
  box-shadow: none;
}

.kegiatan-content
  .search-input
  .kegiatan-content
  .kegiatan-highlight-container
  .highlight {
  border-bottom: 1px solid #828282;
  margin-bottom: 2rem !important;
}

.kegiatan-more-container h5 {
  font-weight: 700;
}

.kegiatan-content .kegiatan-highlight-container a {
  width: inherit;
}

.kegiatan-content .book-container-highlight p:nth-child(even) {
  font-weight: 700;
  font-size: 14px;
  text-align: start;
  margin: 0;
}

.kegiatan-content .book-container-highlight p:nth-child(odd) {
  font-size: 12px;
  text-align: start;
}

.book-container-highlight .book-cover {
  width: 15rem;
  height: 25rem;
  object-fit: cover;
}

.book-container .book-cover {
  width: 15rem;
  height: 25rem;
  object-fit: cover;
}

.book-container p:nth-child(odd) {
  font-weight: 700;
  font-size: 14px;
  text-align: start;
  margin: 0;
}

.book-container p:nth-child(even) {
  font-weight: 200;
  font-size: 12px;
  text-align: start;
}

.book-container-detail .book-title {
  font-weight: 700 !important;
  font-size: 14px !important;
  text-align: start !important;
  margin: 0;
}

.book-container .book-desc {
  font-weight: 200 !important;
  font-size: 12px !important;
  text-align: start !important;
}

/* Kegiatan Detile */
.kegiatan-detail-container {
  border-bottom: 1px solid #eaeaea;
}
.kegiatan-detail-container .flipbook {
  margin: 0;
}
.kegiatan-detail-container .kegiatan-detail-title {
  margin: 0 1rem;
}

.kegiatan-detail-container .kegiatan-more-container a {
  text-decoration: none;
  color: black;
}
.kegiatan-detail-container .kegiatan-more-container a:hover {
  color: #559f4b;
}

.preview-container {
  height: 400px !important;
  width: 100% !important;
  overflow-y: scroll;
}

.kegiatan-detail-title input:focus {
  border: 1px solid #559f4b;
  box-shadow: 0 0 0 0.3rem rgba(85, 159, 75, 0.25);
}
@media (min-width: 768px) {
  .kegiatan-content .search-container {
    display: flex;
    justify-content: start;
  }

  .kegiatan-content .search-input {
    width: 25%;
    margin: 2rem;
    margin-top: 0;
  }

  .kegiatan-content .book-container-highlight img {
    width: 15rem;
  }

  .kegiatan-content .book-container-highlight p:nth-child(even) {
    font-weight: 700;
    font-size: 16px;
    text-align: start;
    margin: 0;
  }

  .kegiatan-content .book-container-highlight p:nth-child(odd) {
    font-size: 14px;
    text-align: start;
  }

  .kegiatan-detail-container .flipbook {
    margin: 2rem 0;
  }

  .kegiatan-detail-container .kegiatan-detail-title {
    margin: 0 10rem;
  }

  .book-container-highlight .book-cover {
    width: 10rem;
    height: 20rem;
    object-fit: cover;
  }

  .book-container .book-cover {
    width: 13rem;
    height: 12rem;
    object-fit: cover;
  }
}
