.card-product {
  width: 17rem;
  padding: 2.5rem;
  padding-bottom: 1rem;
  transition: 0.3s;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.card-product:hover {
  cursor: default;
  color: black;
  text-decoration: none;
}

.hover-card:hover {
  box-shadow: 0 -8px 15px rgba(33, 33, 33, 0.2);
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .card-product {
    width: 17rem;
    padding: 2.5rem;
    transition: 0.3s;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
  }
}
