h1 {
  font-size: 32px;
}

p {
  font-size: 16px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}
